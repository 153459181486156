import axios, { AxiosError, AxiosInstance, isAxiosError } from 'axios';
import { GENERAL_ERROR, IResponseData } from 'src/domain/commonDomain';
import { CommonActions } from 'src/redux/slices/commonSlice';
import { dispatch } from 'src/redux/store';

export const PUBLIC_API = '/v1';
const ADMIN_API = '/v1/admin';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${ADMIN_API}`
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (err) => {
  }
);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (err: AxiosError | any) => {
    if (isAxiosError(err)) {
      const {
        data = {
          message: '',
          code: 0
        }
      } = err.response ?? {};
      let { message = GENERAL_ERROR, code } =
        (data as IResponseData<any>) ?? {};
      if (code === 500) message = GENERAL_ERROR;
      dispatch(
        CommonActions.openAlert({
          isOpen: true,
          message,
          title: 'Oops'
        })
      );
    }
    return Promise.reject(err);
  }
);
