import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Linen from './content/linen';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/content/login')));
const DetailsTransaction = Loader(
  lazy(() => import('src/content/transaction/DetailsTransaction'))
);
const PendingBilling = Loader(lazy(() => import('src/content/PendingBilling')));
const LinenDetails = Loader(
  lazy(() => import('src/content/linen/DetailsLinen'))
);
const CategoryLinen = Loader(lazy(() => import('src/content/categoryLinen')));
const User = Loader(lazy(() => import('src/content/users')));
const RoleUser = Loader(lazy(() => import('src/content/RoleUser')));
const Transactions = Loader(lazy(() => import('src/content/transaction')));
const Reports = Loader(lazy(() => import('src/content/reports')));
const Dashboard = Loader(lazy(() => import('src/content/dashboards/Crypto')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to={'/login'} />
      },
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      
      { path: '', element: <Navigate to={'/dashboard/transaction'} /> },
      {
        path: 'transaction',
        children: [
          { index: true, element: <Transactions /> },
          { path: 'details/:id', element: <DetailsTransaction /> }
        ]
      },
      {
        path: 'pending-billing',
        element: <PendingBilling />
      },
      {
        path: 'linen',
        children: [
          { index: true, element: <Linen /> },
          { path: 'details/:id', element: <LinenDetails /> }
        ]
      },
      {
        path: 'category-linen',
        element: <CategoryLinen />
      },
      {
        path: 'user',
        element: <User />
      },
      { path: 'role-user', element: <RoleUser /> },
      { path: 'reports', element: <Reports /> },
      { path: 'crypto', element: <Dashboard /> }
    ]
  }
];

export default routes;
