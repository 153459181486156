export interface ICommonState {
  isLoading: boolean;
  error: ICommonError;
}

export interface ICommonError {
  isOpen: boolean;
  title: string;
  message: string;
}

export interface IResponseData<T> {
  data?: T;
  message?: string;
  code?: number;
}

export interface IGeneralPagingData<T> {
  count?: number;
  rows?: Array<T>;
}

export interface IPaginationRequest {
  page: number;
  perPage: number;
}

export const GENERAL_ERROR =
  'Ada sesuatu yang salah, periksa jaringan Internet anda dan coba lagi. Atau hubungi IT Support.';

export const GENERAL_SUCCESS = 'Sukses';
