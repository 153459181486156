import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IGeneralPagingData } from 'src/domain/commonDomain';

import {
  ICategoryLinen,
  ICategoryLinenState,
  IReducerCategoryLinenType
} from 'src/domain/categoryLinenDomain';

const initialState: ICategoryLinenState = {
  data: { count: 0, rows: [] },
  modalView: {},
  view: {
    selectedCategoryLinen: null
  }
};

const selector = {
  getData: (state: RootState) => state.categoryLinen.data,
  getModalView: (state: RootState) => state.categoryLinen.modalView,
  getView: (state: RootState) => state.categoryLinen.view
};

const setSelectedCategoryLinen = (
  state: Draft<ICategoryLinenState>,
  action: PayloadAction<number>
) => {
  const { payload } = action;
  const selectedCategory = state.data.rows.find(
    (category) => category.id === payload
  );

  return {
    ...state,
    view: {
      ...state.view,
      selectedCategoryLinen: selectedCategory
    }
  };
};

const changeAttributeSelectedCategory = (
  state: Draft<ICategoryLinenState>,
  action: PayloadAction<any>
) => {
  const { payload } = action;
  return {
    ...state,
    view: {
      ...state.view,
      selectedCategoryLinen: {
        ...state.view.selectedCategoryLinen,
        ...payload
      }
    }
  };
};

const setData: IReducerCategoryLinenType<IGeneralPagingData<ICategoryLinen>> = (
  state,
  action
) => {
  const data = action.payload;
  return {
    ...state,
    data
  };
};

const openModal = (
  state: Draft<ICategoryLinenState>,
  action: PayloadAction<string>
) => {
  const { payload } = action;
  return {
    ...state,
    modalView: {
      [payload]: true
    }
  };
};

const closeModal = (
  state: Draft<ICategoryLinenState>,
  action: PayloadAction<string>
) => {
  const { payload } = action;
  return {
    ...state,
    modalView: {
      [payload]: false
    }
  };
};

const reducers = {
  setData,
  openModal,
  closeModal,
  setSelectedCategoryLinen,
  changeAttributeSelectedCategory
};

const categoryLinenSlice = createSlice({
  name: 'categoryLinen',
  initialState,
  reducers
});

export default categoryLinenSlice.reducer;
export const CategoryLinenSelector = selector;
export const CategoryLinenActions = categoryLinenSlice.actions;
