import {
  FC,
  ChangeEvent,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useRef,
  KeyboardEvent
} from 'react';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  CardHeader,
  Button,
  TextField
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LinenActions, LinenSelector } from 'src/redux/slices/linenSlice';
import { getAllLinen } from 'src/redux/thunk/linenThunk';
import { IPaginationRequest } from 'src/domain/commonDomain';
import { CreateLinenModal } from './Modal';

interface ITableLinenProps {
  children?: ReactNode;
}

const TableLinen: FC<ITableLinenProps> = () => {
  const linensData = useSelector(LinenSelector.getData);
  const dispatch = useDispatch();
  const searchRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const onCreateLinen = useCallback(() => {
    dispatch(LinenActions.setView({ isOpenCreateModal: true }));
  }, [dispatch]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleSubmitSearch = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      dispatch(
        getAllLinen({ page, perPage: limit }, searchRef.current.value ?? '')
      );
    },
    []
  );
  const theme = useTheme();

  useEffect(() => {
    const paginationRequest: IPaginationRequest = { page, perPage: limit };
    dispatch(getAllLinen(paginationRequest, searchRef.current.value ?? ''));
  }, [page, limit]);

  return (
    <Card>
      <CardHeader
        action={
          <Box
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
            gap={'1rem'}
          >
            <Button onClick={onCreateLinen}>Tambah Linen</Button>
            <TextField
              inputRef={searchRef}
              onKeyDown={handleSubmitSearch}
              placeholder="Cari"
            ></TextField>
          </Box>
        }
        title="Table Linen"
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">No</TableCell>
              <TableCell align="center">Linen ID</TableCell>
              <TableCell align="center">Nama Linen</TableCell>
              <TableCell align="center">RFID</TableCell>
              <TableCell align="center">Kategori Linen</TableCell>
              <TableCell align="center">Khusus Untuk</TableCell>
              <TableCell align="center">Telah Diganti Ke</TableCell>
              <TableCell align="center">Opsi Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {linensData.rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  Transaksi Kosong
                </TableCell>
              </TableRow>
            )}
            {linensData.rows.length > 0 &&
              linensData.rows.map((linenData, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {linenData.id}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {linenData.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {linenData.rfid}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {linenData.category.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {linenData.owned?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {linenData.replace?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/dashboard/linen/details/${linenData.id}`}>
                        <Tooltip title="Ubah Linen" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                          >
                            <EditTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateLinenModal />
      <Box p={2}>
        <TablePagination
          component="div"
          count={linensData.count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default TableLinen;
