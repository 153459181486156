import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IGeneralPagingData } from 'src/domain/commonDomain';

import {
  IReducerRoleUserType,
  IRoleUser,
  IRoleUserState
} from 'src/domain/roleUserDomain';

const initialState: IRoleUserState = {
  data: { count: 0, rows: [] },
  modalView: {},
  view: {
    selectedRole: null
  }
};

const selector = {
  getData: (state: RootState) => state.roleUser.data,
  getModalView: (state: RootState) => state.roleUser.modalView,
  getView: (state: RootState) => state.roleUser.view
};

const setSelectedRoleUser = (
  state: Draft<IRoleUserState>,
  action: PayloadAction<number>
) => {
  const { payload } = action;
  const selectedRoleUser = state.data.rows.find(
    (roleUser) => roleUser.id === payload
  );

  return {
    ...state,
    view: {
      ...state.view,
      selectedRoleYUs: selectedRoleUser
    }
  };
};

const changeAttributeRoleUser = (
  state: Draft<IRoleUserState>,
  action: PayloadAction<any>
) => {
  const { payload } = action;
  return {
    ...state,
    view: {
      ...state.view,
      selectedRole: {
        ...state.view.selectedRole,
        ...payload
      }
    }
  };
};

const setData: IReducerRoleUserType<IGeneralPagingData<IRoleUser>> = (
  state,
  action
) => {
  const data = action.payload;
  return {
    ...state,
    data
  };
};

const openModal = (
  state: Draft<IRoleUserState>,
  action: PayloadAction<string>
) => {
  const { payload } = action;
  return {
    ...state,
    modalView: {
      [payload]: true
    }
  };
};

const closeModal = (
  state: Draft<IRoleUserState>,
  action: PayloadAction<string>
) => {
  const { payload } = action;
  return {
    ...state,
    modalView: {
      [payload]: false
    }
  };
};

const reducers = {
  setData,
  openModal,
  closeModal,
  setSelectedRoleUser,
  changeAttributeRoleUser
};

const roleUserSlice = createSlice({
  name: 'roleUser',
  initialState,
  reducers
});

export default roleUserSlice.reducer;
export const RoleUserSelector = selector;
export const RoleUserActions = roleUserSlice.actions;
