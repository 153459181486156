import moment from 'moment';

const months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember'
];

const days = [
  'Minggu',
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  'Jumat',
  'Sabtu',
]
export const formatDate = (
  date: string | number,
  format: string = 'DD/MM/yyyy'
) => {
  moment.locale('id');
  return moment(date).format(format);
};

export const formatDateId = (date: string | number) => {
  const m = moment(date);
  return `${m.format('DD')} ${months[m.month()]} ${m.format('yyyy')}`;
};

export const getpastDates = (numberDates: number) => {
  let result = [];
  for (let i = 1; i <= numberDates; i++) {
    let d = new Date();
    d.setDate(d.getDate() - i);
    result.push(d);
  }
  return result;
};

export const getPastDateNames = (numberDates: number) => {
  const dates = getpastDates(numberDates);
  return dates.map((e: Date) => getDateNames(e))
}

export const getDateNames = (d: Date | string) => {
  if(typeof d === 'string'){
    return days[new Date(d).getDay()];
  }
  return days[d.getDay()];
}