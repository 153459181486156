import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ICreateLinenRequest } from 'src/domain/LinenDomain';
import { CommonActions } from 'src/redux/slices/commonSlice';
import { LinenActions, LinenSelector } from 'src/redux/slices/linenSlice';
import { createLinen } from 'src/redux/thunk/linenThunk';

export const CreateLinenModal = () => {
  const { isOpenCreateModal } = useSelector(LinenSelector.getView);
  const dispatch = useDispatch();
  const nameLinen = useRef<HTMLInputElement>(null);
  const rfid = useRef<HTMLInputElement>(null);
  const categoryId = useRef<HTMLInputElement>(null);
  const userId = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const onClose = () => {
    dispatch(LinenActions.setView({ isOpenCreateModal: false }));
  };

  const onSubmit = () => {
    const isNotPassed =
      !nameLinen.current.value ||
      !categoryId.current.value ||
      !rfid.current.value;

    if (isNotPassed) {
      dispatch(
        CommonActions.openAlert({
          title: 'Oops',
          isOpen: true,
          message:
            'Harap lengkapi semua data yang wajib dimasukkan (Ditandai dengan *)'
        })
      );
      return;
    }
    const request: ICreateLinenRequest = {
      name: nameLinen.current.value,
      categoryId: parseInt(categoryId.current.value),
      rfid: rfid.current.value,
      ownedBy: userId.current.value || null
    };

    dispatch(createLinen(request, navigate));
  };

  const theme = useTheme();
  return (
    <Dialog fullWidth={true} open={isOpenCreateModal}>
      <DialogTitle>Form Tambah Linen</DialogTitle>
      <DialogContent sx={{ color: 'black !important' }}>
        <DialogContentText id="alert-dialog-description">
          Isi kolom-kolom berikut:
        </DialogContentText>

        <Box
          display={'flex'}
          flexDirection={'column'}
          color="black"
          gap={'1rem'}
          marginTop={'1rem'}
        >
          <InputLabel>Nama Linen*</InputLabel>
          <TextField inputRef={nameLinen} placeholder="Nama Linen" />
          <InputLabel>RFID*</InputLabel>
          <Typography color={'red'}>
            Note: Jika anda memasukkan RFID yang sudah ada pada database, maka
            secara otomatis akan menggantikan linen yang memakai RFID tersebut.
          </Typography>
          <TextField inputRef={rfid} placeholder="RFID" />
          <InputLabel>ID Kategori*</InputLabel>
          <TextField inputRef={categoryId} placeholder="ID Kategori" />
          <InputLabel>
            ID Client (Kosongkan jika linen tersedia untuk semua client)
          </InputLabel>
          <TextField inputRef={userId} placeholder="ID Client (Khusus Untuk)" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Batal</Button>
        <Button
          sx={{
            color: theme.colors.success.dark
          }}
          onClick={onSubmit}
        >
          Tambah Linen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
