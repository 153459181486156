import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IGeneralPagingData } from 'src/domain/commonDomain';

import {
  IReducerReportType,
  IReport,
  IReportState
} from 'src/domain/reportDomain';

const initialState: IReportState = {
  data: { count: 0, rows: [] },
  modalView: {},
  view: {
    selectedReport: null
  }
};

const selector = {
  getData: (state: RootState) => state.report.data,
  getModalView: (state: RootState) => state.report.modalView,
  getView: (state: RootState) => state.report.view
};

const setSelectedCategoryLinen = (
  state: Draft<IReportState>,
  action: PayloadAction<string>
) => {
  const { payload } = action;
  const selectedReport = state.data.rows.find(
    (category) => category.id === payload
  );

  return {
    ...state,
    view: {
      ...state.view,
      selectedReport: selectedReport
    }
  };
};

const changeAttributeSelectedCategory = (
  state: Draft<IReportState>,
  action: PayloadAction<any>
) => {
  const { payload } = action;
  return {
    ...state,
    view: {
      ...state.view,
      selectedCategoryLinen: {
        ...state.view.selectedReport,
        ...payload
      }
    }
  };
};

const setData: IReducerReportType<IGeneralPagingData<IReport>> = (
  state,
  action
) => {
  const data = action.payload;
  return {
    ...state,
    data
  };
};

const openModal = (
  state: Draft<IReportState>,
  action: PayloadAction<string>
) => {
  const { payload } = action;
  return {
    ...state,
    modalView: {
      [payload]: true
    }
  };
};

const closeModal = (
  state: Draft<IReportState>,
  action: PayloadAction<string>
) => {
  const { payload } = action;
  return {
    ...state,
    modalView: {
      [payload]: false
    }
  };
};

const reducers = {
  setData,
  openModal,
  closeModal,
  setSelectedCategoryLinen,
  changeAttributeSelectedCategory
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers
});

export default reportSlice.reducer;
export const ReportSelector = selector;
export const ReportActions = reportSlice.actions;
