import { FC, ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { checkLoggedUser } from 'src/redux/thunk/authThunk';
import { useDispatch } from 'react-redux';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkLoggedUser(navigate, 'login'));
  }, []);
  
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {children || <Outlet />}
    </Box>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
