import { isAxiosError } from 'axios';
import { NavigateFunction } from 'react-router';
import { Dispatch } from 'redux';
import {
  getDetailLoggedUserApi,
  ILoginRequest,
  ILoginResponse,
  loginApi
} from 'src/api/authApi';
import { ICommonError, IResponseData } from 'src/domain/commonDomain';
import { IUser } from 'src/domain/userDomain';
import { AuthActions } from '../slices/authSlice';
import { CommonActions } from '../slices/commonSlice';
import { RootState } from '../store';

export const login = (request: ILoginRequest, navigate: NavigateFunction) => {
  return async (dispatch: Dispatch) => {
    dispatch(CommonActions.openLoading());
    try {
      const response = await loginApi(request);
      const { data } = response.data as IResponseData<ILoginResponse>;
      dispatch(AuthActions.setUserData(data.user));
      localStorage.setItem('token', data.token);
      navigate('/dashboard/transaction');
    } catch (e) {
      if (isAxiosError(e)) return;
      const errorData: ICommonError = {
        isOpen: true,
        message: e.message,
        title: 'Oops'
      };
      dispatch(CommonActions.openAlert(errorData));
    } finally {
      dispatch(CommonActions.closeLoading());
    }
  };
};

export const checkLoggedUser = (navigate: NavigateFunction, path?: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { userData } = getState().auth;
    const token = localStorage.getItem('token');
    if (token && userData.id && path === 'login') {
      navigate('/dashboard/transaction');
    }
    if (!token) {
      navigate('/login');
      return;
    }
    if (!userData.id) {
      try {
        const response = await getDetailLoggedUserApi();
        const { data } = response.data as IResponseData<IUser>;
        dispatch(AuthActions.setUserData(data));
        if (path === 'login') {
          navigate('/dashboard/transaction');
        }
      } catch (e) {
        navigate('/login');
      }
    }
  };
};
