import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IGeneralPagingData } from './commonDomain';
import { IRoleUser } from './roleUserDomain';

export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export interface IUser {
  id?: number;
  username?: string;
  barcodeId?: string;
  name?: string;
  address?: string;
  roleUserId?: number;
  phoneNumber?: string;
  email?: string;
  childOf?: number;
  parent?: IUser;
  roleUser?: IRoleUser;
}

export interface ICreateUserRequest extends IUser {
  password?: string;
}

export const emptyUser: IUser = {
  barcodeId: '',
  id: 0,
  name: '',
  username: '',
  address: '',
  roleUserId: 0,
  email: '',
  phoneNumber: ''
};

export interface IUserViewState {
  selectedUser: IUser;
}

export interface IUserState {
  data: IGeneralPagingData<IUser>;
  modalView: any;
  view: IUserViewState;
}

export type IReducerUserType<T> = (
  state?: Draft<IUserState>,
  action?: PayloadAction<T>
) => IUserState;

export interface IAuthSliceState {
  userData: IUser;
  isLoggedIn: boolean;
}

export type IReduceAuthType<T> = (
  state?: Draft<IAuthSliceState>,
  action?: PayloadAction<T>
) => IAuthSliceState;
