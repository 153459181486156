import { IPaginationRequest } from 'src/domain/commonDomain';
import {
  ICreateLinenRequest,
  IUpdateLinenRequest
} from 'src/domain/LinenDomain';
import { axiosInstance } from './axiosInstance';

export const LINEN = '/linen';

export const getAllLinenApi = (
  { page, perPage }: IPaginationRequest,
  search: string
) => {
  return axiosInstance.get(
    `${LINEN}?page=${page + 1}&perPage=${perPage}&search=${search}`
  );
};

export const createLinenApi = (request: ICreateLinenRequest) => {
  return axiosInstance.post(`${LINEN}/create`, request);
};

export const deleteLinenApi = (linenId: string) => {
  return axiosInstance.delete(`${LINEN}/delete?id=${linenId}`);
};

export const getLinenByIdApi = (linenId: string) => {
  return axiosInstance.get(`${LINEN}/${linenId}`);
};

export const updateLinenApi = (request: IUpdateLinenRequest) => {
  return axiosInstance.put(`${LINEN}/update`, request);
};
