import { IUser } from 'src/domain/userDomain';
import { axiosInstance, PUBLIC_API } from './axiosInstance';

const USER_API = '/user';
const LOGIN_API = `${process.env.REACT_APP_API_URL}${PUBLIC_API}${USER_API}/login`;
const GET_DETAIL_USER_API = `${process.env.REACT_APP_API_URL}${PUBLIC_API}${USER_API}/details`;

export interface ILoginRequest {
  username: string;
  password: string;
}

export interface ILoginResponse {
  token: string;
  user: IUser;
}

export const loginApi = (credentials: ILoginRequest) => {
  return axiosInstance.post(LOGIN_API, credentials);
};

export const getDetailLoggedUserApi = () => {
  return axiosInstance.get(GET_DETAIL_USER_API);
};
