import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IGeneralPagingData } from 'src/domain/commonDomain';
import {
  ILinen,
  ILinenSliceState,
  ILinenViewState,
  IReducerLinenType
} from 'src/domain/LinenDomain';

const initialState: ILinenSliceState = {
  data: { count: 0, rows: [] },
  view: {
    
    isOpenCreateModal: false
  }
};

const selector = {
  getData: (state: RootState) => state.linen.data,
  getView: (state: RootState) => state.linen.view
};

const setData: IReducerLinenType<IGeneralPagingData<ILinen>> = (
  state,
  action
) => {
  const data = action.payload;
  return {
    ...state,
    data
  };
};

const setView: IReducerLinenType<ILinenViewState> = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    view: {
      ...state.view,
      ...payload
    }
  };
};

const reducers = {
  setData,
  setView
};

const linenSlice = createSlice({
  name: 'linen',
  initialState,
  reducers
});

export default linenSlice.reducer;
export const LinenSelector = selector;
export const LinenActions = linenSlice.actions;
