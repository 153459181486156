import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import categoryLinenSlice from './slices/categoryLinenSlice';
import commonSlice from './slices/commonSlice';
import linenSlice from './slices/linenSlice';
import orderSlice from './slices/orderSlice';
import pendingBillingSlice from './slices/pendingBillingSlice';
import userSlice from './slices/userSlice';
import roleUserSlice from './slices/roleUserSlice';
import reportSlice from './slices/reportSlice';
import dashboardSlice from './slices/dashboardSlice';

const store = configureStore({
  reducer: {
    order: orderSlice,
    auth: authSlice,
    common: commonSlice,
    pendingBilling: pendingBillingSlice,
    linen: linenSlice,
    categoryLinen: categoryLinenSlice,
    user: userSlice,
    roleUser: roleUserSlice,
    report: reportSlice,
    dashboard: dashboardSlice
  }
});
export type RootState = ReturnType<typeof store.getState>;
export const { dispatch } = store;
export default store;
