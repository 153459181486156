import { Typography, Grid } from '@mui/material';
import { ReactNode } from 'react';

interface IPageHeaderProps {
  children?: ReactNode;
  title: string;
  description: string;
}

function PageHeader({ title, description }: IPageHeaderProps) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2">{description}</Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
