import { createSlice } from '@reduxjs/toolkit';
import {
  IDashboardState,
  IDashboardViewState,
  IReducerDashboardType
} from 'src/domain/dashboardDomain';
import { getDateNames } from 'src/utils/dateUtils';
import { RootState } from '../store';

const initialState: IDashboardState = {
  view: {
    dashboardDetails: {
      orderPastSevenDay: [],
      totalClient: 0,
      totalLinen: 0
    }
  }
};

const selector = {
  getView: (state: RootState) => state.dashboard.view
};

const setView: IReducerDashboardType<IDashboardViewState> = (state, action) => {
  const data = action.payload;
  return {
    ...state,
    view: {
      ...state.view,
      ...data
    }
  };
};

const reducers = {
  setView
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers
});

export default dashboardSlice.reducer;
export const DashboardSelector = selector;
export const DashboardAction = dashboardSlice.actions;
