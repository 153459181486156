import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IOrder } from 'src/domain/orderDomain';
import { IGeneralPagingData } from 'src/domain/commonDomain';

interface IOrderViewState {
  selectedOrder?: IOrder;
}

interface IOrdersSliceState {
  data?: IGeneralPagingData<IOrder>;
  view?: IOrderViewState;
}

type IReduceOrderType<T> = (
  state?: Draft<IOrdersSliceState>,
  action?: PayloadAction<T>
) => IOrdersSliceState;

const initialState: IOrdersSliceState = {
  data: {count: 0, rows: []},
  view: {
    selectedOrder: {
      id: '',
      user: null,
      isCompleted: false,
      isPaid: false,
      orderDetails: [],
      createdAt: ''
    }
  }
};

const selector = {
  getData: (state: RootState) => state.order.data,
  getView: (state: RootState) => state.order.view
};

const setData: IReduceOrderType<IGeneralPagingData<IOrder>> = (state, action) => {
  const data = action.payload;
  return {
    ...state,
    data
  };
};

const setView: IReduceOrderType<IOrderViewState> = (state, action) => {
  const data = action.payload;
  return {
    ...state,
    view: {
      ...state.view,
      ...data
    }
  };
};

const reducers = {
  setData,
  setView
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers
});

export default orderSlice.reducer;
export const OrderSelector = selector;
export const OrderActions = orderSlice.actions;
