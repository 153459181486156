import { NavigateFunction } from 'react-router';
import { Dispatch } from 'redux';
import {
  createLinenApi,
  deleteLinenApi,
  getAllLinenApi,
  getLinenByIdApi,
  updateLinenApi
} from 'src/api/linenApi';
import {
  IGeneralPagingData,
  IPaginationRequest,
  IResponseData
} from 'src/domain/commonDomain';
import {
  ICreateLinenRequest,
  ILinen,
  IUpdateLinenRequest
} from 'src/domain/LinenDomain';
import { CommonActions } from '../slices/commonSlice';
import { LinenActions } from '../slices/linenSlice';

export const getAllLinen = (
  paginationRequest: IPaginationRequest,
  search: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(CommonActions.openLoading());
    try {
      const response = await getAllLinenApi(paginationRequest, search);
      const { data }: IResponseData<IGeneralPagingData<ILinen>> = response.data;
      dispatch(LinenActions.setData(data));
    } catch (e) {
    } finally {
      dispatch(CommonActions.closeLoading());
    }
  };
};

export const createLinen = (
  request: ICreateLinenRequest,
  navigate: NavigateFunction
) => {
  return async (dispatch: Dispatch) => {
    dispatch(CommonActions.openLoading());
    try {
      const response = await createLinenApi(request);
      const { message }: IResponseData<ILinen> = response.data;
      dispatch(
        CommonActions.openAlert({ isOpen: true, title: 'Success', message })
      );
      dispatch(LinenActions.setView({ isOpenCreateModal: false }));
      navigate(0);
    } catch (e) {
    } finally {
      dispatch(CommonActions.closeLoading());
    }
  };
};

export const deleteLinen = (linenId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(CommonActions.openLoading());
    try {
      const response = await deleteLinenApi(linenId);
      const { message }: IResponseData<any> = response.data;
      dispatch(
        CommonActions.openAlert({ isOpen: true, title: 'Success', message })
      );
    } catch (e) {
    } finally {
      dispatch(CommonActions.closeLoading());
    }
  };
};

export const getDetailsLinen = (linenId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(CommonActions.openLoading());
    try {
      const response = await getLinenByIdApi(linenId);
      const { data }: IResponseData<ILinen> = response.data;
      dispatch(LinenActions.setView({ selectedLinenDetails: data }));
    } catch (e) {
    } finally {
      dispatch(CommonActions.closeLoading());
    }
  };
};

export const updateLinen = (
  request: IUpdateLinenRequest,
  navigate: NavigateFunction
) => {
  return async (dispatch: Dispatch) => {
    dispatch(CommonActions.openLoading());
    try {
      const response = await updateLinenApi(request);
      const {}: IResponseData<ILinen> = response.data;
      navigate(0);
    } catch (e) {
    } finally {
      dispatch(CommonActions.closeLoading());
    }
  };
};
