import { createSlice } from '@reduxjs/toolkit';
import { IAuthSliceState, IReduceAuthType, IUser } from 'src/domain/userDomain';
import { RootState } from '../store';

const initialState: IAuthSliceState = {
  userData: {
    barcodeId: '',
    id: 0,
    username: ''
  },
  isLoggedIn: false
};

const selector = {
  getUserdata: (state: RootState) => state.auth.userData
};

const setUserData: IReduceAuthType<IUser> = (state, action) => {
  const data = action.payload;
  return {
    ...state,
    userData: data,
    isLoggedIn: true
  };
};

const logout: IReduceAuthType<string> = (state, action) => {
  return {
    ...state,
    ...initialState
  };
};

const reducers = {
  setUserData,
  logout
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers
});

export default authSlice.reducer;
export const AuthSelector = selector;
export const AuthActions = authSlice.actions;
