import {
  Document,
  Font,
  Page,
  Styles,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';
import moment from 'moment';

import { ReactNode } from 'react';
import { IOrderDetail } from 'src/domain/orderDomain';
import { IPendingBillingDetails } from 'src/domain/pendingBillingDomain';
import { formatDate, formatDateId } from 'src/utils/dateUtils';

Font.register({
  family: 'Open Sans',
  src: 'https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3aCWcynf_cDxXwCLxiixG1c.ttf',
  fontWeight: 'bold'
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    marginTop: 20,
    marginRight: 20,
    paddingBottom: 40,
    fontFamily: 'Open Sans'
  },
  profile: {
    width: '90%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  profileFont: {
    fontSize: 12,
    textAlign: 'right'
  },
  generalFont: {
    fontSize: 8
  },
  headerContainer: {
    margin: 10,
    padding: 10,
    backgroundColor: '#d3d3e0'
  },
  mainContainer: {
    paddingLeft: 50,
    paddingRight: 50
  },
  rowTable: {}
});

interface IRowTableProps {
  children: ReactNode;
}

const RowTable = ({ children }: IRowTableProps) => {
  const rowStyle = StyleSheet.create({
    rowTable: {
      flexDirection: 'row',
      width: '100%'
    }
  });
  return <View style={rowStyle.rowTable}>{children}</View>;
};

interface ICellTableProps {
  children: ReactNode;
  style?: Styles;
  width: number | string;
  borderLeft: number;
  borderRight: number;
  textAlign?: 'left' | 'right' | 'center' | 'justify'; // ?
  borderBottom?: number | string;
}

const fmod = function (a, b) {
  return Number((a - Math.floor(a / b) * b).toPrecision(8));
};

const terbilang = (nilai) => {
  const huruf = [
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
    'Sepuluh',
    'Sebelas'
  ];
  let temp = '';
  if (nilai < 12) {
    temp = ' ' + huruf[nilai];
  } else if (nilai < 20) {
    temp = terbilang(nilai - 10) + ' Belas';
  } else if (nilai < 100) {
    temp = terbilang(Math.floor(nilai / 10)) + ' Puluh' + terbilang(nilai % 10);
  } else if (nilai < 200) {
    temp = ' Seratus' + terbilang(nilai - 100);
  } else if (nilai < 1000) {
    temp =
      terbilang(Math.floor(nilai / 100)) + ' Ratus' + terbilang(nilai % 100);
  } else if (nilai < 2000) {
    temp = ' Seribu' + terbilang(nilai - 1000);
  } else if (nilai < 1000000) {
    temp =
      terbilang(Math.floor(nilai / 1000)) + ' Ribu' + terbilang(nilai % 1000);
  } else if (nilai < 1000000000) {
    temp =
      terbilang(Math.floor(nilai / 1000000)) +
      ' Juta' +
      terbilang(nilai % 1000000);
  } else if (nilai < 1000000000000) {
    temp =
      terbilang(Math.floor(nilai / 1000000000)) +
      ' Milyar' +
      terbilang(fmod(nilai, 1000000000));
  } else if (nilai < 1000000000000000) {
    temp =
      terbilang(Math.floor(nilai / 1000000000000)) +
      ' Trilyun' +
      terbilang(fmod(nilai, 1000000000000));
  }
  return temp;
};

const CellTable = ({
  children,
  style,
  width,
  borderLeft,
  borderRight,
  textAlign,
  borderBottom
}: ICellTableProps) => {
  return (
    <View
      style={{
        width,
        borderLeft,
        borderRight,
        borderBottom: borderBottom ?? 1
      }}
    >
      <Text
        style={[
          style,
          styles.generalFont,
          { textAlign: textAlign ?? 'center', padding: 5 }
        ]}
      >
        {children}
      </Text>
    </View>
  );
};

const data = [
  { item: 'Linen Dokter', jumlah: '100 Kg', price: '10000', total: '1000000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Linen Pasien', jumlah: '50 Kg', price: '10000', total: '500000' },
  { item: 'Sprei', jumlah: '100 Pcs', price: '7000', total: '700000' }
];

interface IInvoicePendingBillingProps {
  children?: ReactNode;
  detailsPendingBilling: IPendingBillingDetails;
}

const InvoicePendingBilling = ({
  detailsPendingBilling
}: IInvoicePendingBillingProps) => {
  const ordersDetails: Array<IOrderDetail> = [];
  detailsPendingBilling.orders.rows.map((order) => {
    ordersDetails.push(...order.orderDetails);
  });

  let grandTotal = 0;
  ordersDetails.map((orderDetail) => {
    grandTotal += orderDetail.amount * orderDetail.category.price;
  });

  return (
    <Document>
      <Page size="A4" style={styles.section}>
        <View style={styles.profile}>
          <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
            PT Bakti Kasih Nugraha
          </Text>
          <View style={{ marginTop: '3%', width: '30%' }}>
            <Text style={styles.profileFont}>
              Jalan. Desa, RT.003/RW.010, Gandoang, Kec. Cileungsi,
            </Text>
            <Text style={styles.profileFont}>
              Kabupaten Bogor, Jawa Barat 16820
            </Text>
          </View>
        </View>
        <View style={styles.headerContainer}>
          <Text style={styles.generalFont}>Invoice #12312312</Text>
          <Text style={[styles.generalFont, { marginTop: 18 }]}>
            Invoice Date {formatDate(moment().toString(), 'DD/MM/yyyy')}
          </Text>
          <Text style={styles.generalFont}>
            Due Date{' '}
            {formatDate(moment().add(14, 'day').toString(), 'DD/MM/yyyy')}
          </Text>
        </View>

        <View style={styles.mainContainer}>
          <Text>Invoiced To</Text>
          <Text>-</Text>
          <View style={{ width: '30%' }}>
            <Text style={[styles.generalFont]}>
              ATTN: {detailsPendingBilling.user.name}
            </Text>
            <Text style={[styles.generalFont]}>
              {detailsPendingBilling.user.address}
            </Text>
          </View>

          <View style={{ width: '100%', border: 1, marginTop: 15 }}>
            <RowTable>
              <CellTable borderLeft={0} borderRight={1} width={'10%'}>
                No
              </CellTable>
              <CellTable borderLeft={0} borderRight={1} width={'40%'}>
                Item
              </CellTable>
              <CellTable borderLeft={0} borderRight={1} width={'10%'}>
                Jumlah
              </CellTable>
              <CellTable borderLeft={0} borderRight={1} width={'20%'}>
                Harga Satuan (Rp)
              </CellTable>
              <CellTable borderLeft={0} borderRight={0} width={'20%'}>
                Total (Rp)
              </CellTable>
            </RowTable>
            {ordersDetails.map((orderDetail, index) => {
              // order.orderDetails.map((orderDetail) => {
              return (
                <>
                  <RowTable>
                    <CellTable borderLeft={0} borderRight={1} width={'10%'}>
                      {index + 1}
                    </CellTable>
                    <CellTable
                      textAlign="left"
                      borderLeft={0}
                      borderRight={1}
                      width={'40%'}
                    >
                      {orderDetail.category.name}
                    </CellTable>
                    <CellTable borderLeft={0} borderRight={1} width={'10%'}>
                      {orderDetail.amount} {orderDetail.category.unit}
                    </CellTable>
                    <CellTable borderLeft={0} borderRight={1} width={'20%'}>
                      {orderDetail.category.price}
                    </CellTable>
                    <CellTable borderLeft={0} borderRight={0} width={'20%'}>
                      {orderDetail.category.price * orderDetail.amount}
                    </CellTable>
                  </RowTable>
                </>
              );
              // });
            })}
            <RowTable>
              <CellTable
                borderBottom={0}
                width={'80%'}
                textAlign={'right'}
                borderLeft={0}
                borderRight={1}
              >
                Grand Total
              </CellTable>
              <CellTable
                borderBottom={0}
                width={'20%'}
                borderLeft={0}
                borderRight={0}
              >
                {grandTotal}
              </CellTable>
            </RowTable>
          </View>
          <View style={{ width: '100%', padding: 5, textAlign: 'right' }}>
            <Text style={[styles.generalFont]}>
              Terbilang: {terbilang(grandTotal)} Rupiah
            </Text>
          </View>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ gap: 10 }}>
              <Text style={styles.generalFont}>Pembayaran: </Text>
              <Text style={styles.generalFont}>
                Nama: PT Bakti Kasih Anugerah
              </Text>
              <Text style={styles.generalFont}>No. Rek: BCA-102010100</Text>
              <Text style={styles.generalFont}>Email: billing@bka.co.id</Text>
              <Text style={styles.generalFont}>Lampiran 1 Lembar</Text>
            </View>
            <View>
              <Text style={styles.generalFont}>
                Bogor, {formatDateId(moment().toString())}
              </Text>
              <Text style={[styles.generalFont, { marginTop: 60 }]}>
                Tri Kusumo
              </Text>
              <Text style={styles.generalFont}>(Direktur Keuangan)</Text>
            </View>
          </View>
        </View>
      </Page>

      <Page size={'A4'} style={{ padding: 30 }}>
        <Text style={{ fontSize: 15 }}>Perihal: Lampiran 1</Text>
        <View style={{ width: '100%', border: 1, marginTop: 15 }}>
          <RowTable>
            <CellTable borderLeft={0} borderRight={1} width={'10%'}>
              No
            </CellTable>
            <CellTable borderLeft={0} borderRight={1} width={'40%'}>
              Item
            </CellTable>
            <CellTable borderLeft={0} borderRight={1} width={'30%'}>
              Tanggal Pengambilan
            </CellTable>
            <CellTable borderLeft={0} borderRight={1} width={'10%'}>
              Quantity
            </CellTable>
            <CellTable borderLeft={0} borderRight={0} width={'10%'}>
              Satuan
            </CellTable>
          </RowTable>

          {ordersDetails.map((orderDetail, index) => {
            const isLast = index + 1 === ordersDetails.length;
            const borderBottom = isLast ? 0 : 1;
            return (
              <RowTable key={index}>
                <CellTable
                  borderLeft={0}
                  borderRight={1}
                  borderBottom={borderBottom}
                  width={'10%'}
                >
                  {index + 1}
                </CellTable>
                <CellTable
                  borderLeft={0}
                  borderRight={1}
                  textAlign={'left'}
                  borderBottom={borderBottom}
                  width={'40%'}
                >
                  {orderDetail.category.name}
                </CellTable>
                <CellTable
                  borderLeft={0}
                  borderRight={1}
                  borderBottom={borderBottom}
                  width={'30%'}
                >
                  {formatDate(orderDetail.createdAt)}
                </CellTable>
                <CellTable
                  borderLeft={0}
                  borderRight={1}
                  borderBottom={borderBottom}
                  width={'10%'}
                >
                  {orderDetail.amount}
                </CellTable>
                <CellTable
                  borderLeft={0}
                  borderRight={0}
                  borderBottom={borderBottom}
                  width={'10%'}
                >
                  {orderDetail.category.unit}
                </CellTable>
              </RowTable>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePendingBilling;
