import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  IPendingBillingDetails,
  IPendingBillingState,
  IReducerPendingBillingType
} from 'src/domain/pendingBillingDomain';
import { emptyUser, IUser } from 'src/domain/userDomain';
import { RootState } from '../store';

const initialState: IPendingBillingState = {
  data: [],
  detailsPendingBilling: { user: emptyUser, orders: { count: 0, rows: [] } },
  isOpenModalConfirm: false
};

const selector = {
  getPendingBilling: (state: RootState) => state.pendingBilling.data,
  getDetailsPendingBilling: (state: RootState) =>
    state.pendingBilling.detailsPendingBilling,
  isOpenModalConfirm: (state: RootState) =>
    state.pendingBilling.isOpenModalConfirm
};

const openConfirmModal = (
  state: Draft<IPendingBillingState>,
  action: PayloadAction<string>
) => {
  return {
    ...state,
    isOpenModalConfirm: true
  };
};

const closeConfirmModal = (
  state: Draft<IPendingBillingState>,
  action: PayloadAction<string>
) => {
  return {
    ...state,
    isOpenModalConfirm: false
  };
};

const setData: IReducerPendingBillingType<Array<IUser>> = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    data: payload
  };
};

const setDetailsPendingBilling: IReducerPendingBillingType<
  IPendingBillingDetails>
= (state, action) => {
  const { payload } = action;
  return {
    ...state,
    detailsPendingBilling: payload
  };
};

const reducers = {
  setData,
  setDetailsPendingBilling,
  openConfirmModal,
  closeConfirmModal
};

const pendingBillingSlice = createSlice({
  name: 'pendingBilling',
  initialState,
  reducers
});

export default pendingBillingSlice.reducer;
export const PendingBillingSelector = selector;
export const PendingBillingActions = pendingBillingSlice.actions;
