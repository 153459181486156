import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ICommonError, ICommonState } from 'src/domain/commonDomain';
import { RootState } from '../store';

const initialState: ICommonState = {
  isLoading: false,
  error: {
    isOpen: false,
    message: '',
    title: ''
  }
};

const selector = {
  isLoading: (state: RootState) => state.common.isLoading,
  getError: (state: RootState) => state.common.error
};

const openLoading = (
  state: Draft<ICommonState>,
  action: PayloadAction<string>
) => {
  return {
    ...state,
    isLoading: true
  };
};

const closeLoading = (
  state: Draft<ICommonState>,
  action: PayloadAction<string>
) => {
  return {
    ...state,
    isLoading: false
  };
};

const openAlert = (
  state: Draft<ICommonState>,
  action: PayloadAction<ICommonError>
) => {
  return {
    ...state,
    error: {
      ...action.payload
    }
  };
};

const closeAlert = (
  state: Draft<ICommonState>,
  action: PayloadAction<string>
) => {
  return {
    ...state,
    error: {
      ...state.error,
      isOpen: false
    }
  };
};

const reducers = {
  openLoading,
  closeLoading,
  openAlert,
  closeAlert
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers
});

export default commonSlice.reducer;
export const CommonSelector = selector;
export const CommonActions = commonSlice.actions;
