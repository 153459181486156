import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InvoicePendingBilling from 'src/content/PendingBilling/pdf';
import { CommonActions, CommonSelector } from 'src/redux/slices/commonSlice';
import {
  PendingBillingActions,
  PendingBillingSelector
} from 'src/redux/slices/pendingBillingSlice';

export const ErrorModal = () => {
  const { isOpen, message, title } = useSelector(CommonSelector.getError);
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(CommonActions.closeAlert());
  }, [dispatch]);

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>  
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
};

export const LoadingModal = () => {
  const isLoading = useSelector(CommonSelector.isLoading);
  return (
    <Dialog sx={{ zIndex: '7000 !important' }} open={isLoading}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <CircularProgress color="primary" />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

function downloadBlob(blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);
  console.log(blobUrl);

  // Create a link element
  const link = document.createElement('a');
  link.target = '_blank';
  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export const ConfirmGeneratePdfModal = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isOpenModalConfirm = useSelector(
    PendingBillingSelector.isOpenModalConfirm
  );
  const detailsPendingBilling = useSelector(
    PendingBillingSelector.getDetailsPendingBilling
  );
  const handleClose = useCallback(() => {
    dispatch(PendingBillingActions.closeConfirmModal());
  }, []);

  const onSubmit = useCallback(async () => {
    const blob = await pdf(
      <InvoicePendingBilling detailsPendingBilling={detailsPendingBilling} />
    ).toBlob();
    downloadBlob(blob, `Invoice-${detailsPendingBilling.user.name}.pdf`);
    handleClose();
  }, [detailsPendingBilling]);

  return (
    <Dialog open={isOpenModalConfirm}>
      <DialogTitle>Konfirmasi</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Apakah Anda yakin untuk mendownload invoice dengan rentang waktu yang
          tertera ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Batal</Button>

        <Button
          sx={{
            color: theme.colors.success.dark
          }}
          onClick={onSubmit}
        >
          Yakin
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const GenerateInvoiceModal = () => {};
